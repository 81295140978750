import React from 'react'
import { BasicCompare } from '../components/compare/basicCompare'
import { LayoutWrapper } from '../components/layout/layoutWrapper'
import { useNavigationItems } from '../hooks/useNavigationItems'
import { AppLocale, GenerateLocales } from '@hrk/types'
import { SiteMetadata, useSiteMetadata } from '../hooks/useSiteConfig'
import { Seo } from '../components/seo/Seo'

const Compare: React.FC = () => {
  const currentSlug = '/en/compare'
  const currentLocale: AppLocale = GenerateLocales.de
  const seoTitle = 'Compare'
  const seoDescription = 'Compare further training offers on hoch und weit'
  const { collectBreadCrumbs } = useNavigationItems()
  const currentPagePath = collectBreadCrumbs({ slug: currentSlug, title: 'Compare', type: 'EditorialPages' })
  return (
    <>
      <Seo title={seoTitle} slug={currentSlug} locale={currentLocale} description={seoDescription}></Seo>
      <LayoutWrapper
        currentTitle="Compare"
        currentLocale={currentLocale}
        currentSlug={currentSlug}
        currentPath={currentPagePath}
        otherLocales={[{ locale: GenerateLocales.de, slug: '/vergleich/' }]}
      >
        <BasicCompare />
      </LayoutWrapper>
    </>
  )
}

export const Head = ({ location, params, data, pageContext }) => {
  const site: SiteMetadata = useSiteMetadata(pageContext.locale as AppLocale)
  return <link rel="canonical" href={`${site.metadata.siteUrl}/en/compare/`} />
}

export default Compare
